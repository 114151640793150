import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import PageHead from "@Components/PageHead";
import "@styles/services/services.scss";
import HowWeWork from "@Components/HowWeWork";
import CallToAction from "@Components/CallToAction";

// tslint:disable: max-line-length
function Services() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.serviceHero.childImageSharp.fluid}>
        <h2 className="head-title">
          What <span>we can</span> do
        </h2>
      </PageHead>
      <div className="container servicePage">
        <div className="service">
          <div className="service__img">
            <img
              src={require("../../../static/images/service_frontend.svg")}
              alt="Frontend Service"
            />
          </div>
          <div className="service__info">
            <h2 className="service__info--title">Front End</h2>
            <div className="service__info--body">
              <p>
                Today frontend web development is fast-paced with web
                technologies evolving faster than ever.
              </p>
              <p>
                Companies have to keep up with these changes and incorporate new
                standards, tools, and design to attract users.
              </p>
              <p>
                Not only we are embracing these changes but also contribute to
                developing new tools for open source.
              </p>
            </div>
            <div className="service__info--link">
              <Link to="/services/frontend">More Frontend</Link>
            </div>
          </div>
        </div>
        <div className="service">
          <div className="service__info">
            <h2 className="service__info--title">Back End</h2>
            <div className="service__info--body">
              <p>Backend is where all the magic happens.</p>
              <p>
                Building a robust and a solid backend architecture not only
                helps the product to perform well under a heavy load but also
                easy to scale, manage and upgrade in the future.
              </p>
              <p>We make sure those checkpoints are all ticked.</p>
            </div>
            <div className="service__info--link">
              <Link to="/services/backend">More Backend</Link>
            </div>
          </div>
          <div className="service__img">
            <img
              src={require("../../../static/images/services_backend.svg")}
              alt="Frontend Service"
            />
          </div>
        </div>
        <div className="service">
          <div className="service__img">
            <img
              src={require("../../../static/images/service_mobile.svg")}
              alt="Frontend Service"
            />
          </div>
          <div className="service__info">
            <h2 className="service__info--title">Mobile</h2>
            <div className="service__info--body">
              <p>
                Mobile development is skyrocketed in the last decade. Billions
                of peoples are using a smartphone as primary communication.
              </p>
              <p>
                This gives a perfect platform for your idea to share and spread.
              </p>
              <p>
                Developing a cross-platform application can increase the reach
                of the product. And React Native is an excellent choice to
                develop a cross-platform application using a single code base.
              </p>
            </div>
            <div className="service__info--link">
              <Link to="/services/mobile">More Mobile</Link>
            </div>
          </div>
        </div>
      </div>
      <HowWeWork />
      <CallToAction />
    </>
  );
}
export default Services;

export const PageQuery = graphql`
  query {
    serviceHero: file(relativePath: { eq: "serviceHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
