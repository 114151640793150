import React, { useEffect } from "react";
import { IoIosBulb, IoIosCode } from "react-icons/io";
import useScreenObserver from "@Utils/Observer";
import "./styles/howWeWork.scss";

function HowWeWork() {
  const [visible, setRef] = useScreenObserver({ rootMargin: "-100px" });

  useEffect(() => {
    if (visible && setRef.current) {
      setRef.current.classList.add("heading-animation");
    }
  }, [setRef, visible]);
  return (
    <section className="howWeWork">
      <div className="container services">
        <div className="services__head">
          <div className="services__heading">
            <h2 ref={setRef} className="section-heading">
              How
              <span className="sub-head"> we </span>
              do
            </h2>
            <h3 className="section-heading__sub">
              How we work on a project from a simple idea to full-fledged
              product ready to serve users across the world.
            </h3>
          </div>
        </div>
        <div className="workProcess">
          <div className="workProcess__step">
            <div className="workProcess__step--icon">
              <IoIosBulb
                style={{
                  width: "8rem",
                  height: "8rem",
                  color: "#fff",
                }}
              />
            </div>
            <h3>Idea</h3>
            <p>
              Taking the idea and carefully structuring the application design
              and process map
            </p>
          </div>
          <div className="workProcess__step">
            <div className="workProcess__step--icon">
              <IoIosCode
                style={{
                  width: "8rem",
                  height: "8rem",
                  color: "#fff",
                }}
              />
            </div>
            <h3>Code</h3>
            <p>
              Coding and developing the most optimal solution for product and
              turning the idea into reality.
            </p>
          </div>
          <div className="workProcess__step">
            <div className="workProcess__step--icon">
              <span
                style={{ fontSize: "6rem", padding: "0 1rem", color: "#fff" }}
              >
                📦
              </span>
            </div>
            <h3>Release</h3>
            <p>
              Time to show the product to the World, We make sure the relase
              process is smooth and successful.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWeWork;
